<template>
  <div class="content-advance-purchase-all-global">
    <!-- #region contenedor de filtros  -->
    <div class="content-advance-purchase-filter-all-global">
      <div class="content-advance-purchase-search-global">
        <v-text-field
          class="custom-placeholder"
          outlined
          dense
          background-color="#FAFAFA"
          clearable
          v-model="sSearch"
          append-icon="mdi-magnify"
          :placeholder="'Búsqueda general...'"
        >
        </v-text-field>
      </div>
    </div>
    <!-- #endregion contenedor de filtros  -->

    <!-- #region contenedor tabla -->
    <div class="content-advance-purchase-table-all-global">
      <v-simple-table class="table-global" fixed-header>
        <template>
          <thead v-if="!bLoading">
            <tr>
              <th
                v-for="(item, i) in aHeaderTable"
                :key="i"
                :class="item.sClass"
              >
                {{ item.sName }}
              </th>
            </tr>
          </thead>
          <tbody v-if="!bLoading">
            <!-- #region cuerpo de tabla con arreglo lleno -->
            <tr v-for="(item, index) in itemsRequestPerConcept" :key="index">
              <!-- #region Fila Concepto -->
              <td class="text-left">
                <span v-if="item.bEditConcept">
                  <v-text-field
                    v-model="item.sConcept"
                    class="input-filled-global min-w-200"
                    placeholder="Concepto"
                    solo
                    flat
                    hide-details
                    dense
                  ></v-text-field>
                </span>
                <span v-else>
                  {{ item.sConcept }}
                </span>
              </td>
              <!-- #endregion Fila Concepto -->

              <!-- #region Fila Cantidad comprada -->
              <td class="text-center">
                <div class="d-flex justify-center">
                  <span v-if="item.bEditConcept">
                    <!-- <v-text-filed-currency
                      v-model="item.dQuantity"
                      :options="oOptionUnit"
                      solo
                      flat
                      sClass="input-filled-global w-100"
                      placeholder="Cantidad"
                      hide-details
                      dense
                    /> -->
                    <v-text-field
                      type="number"
                      v-model="item.dQuantity"
                      solo
                      flat
                      class="input-filled-global w-100"
                      placeholder="Cantidad"
                      :step="0.01"
                      hide-details
                      dense
                    >
                    </v-text-field>
                  </span>
                  <span v-else>
                    {{ item.dQuantity }}
                  </span>
                </div>
              </td>
              <!-- #endregion Fila Cantidad comprada -->

              <!-- #region Fila cantidad recibida -->
              <td class="text-center">
                <!-- <span v-if="item.bEditConcept">
                  <v-text-filed-currency
                    v-model="item.dUsedQuantity"
                    :options="oOptionUnit"
                    solo
                    flat
                    sClass="input-filled-global"
                    placeholder="Cantidad"
                    hide-details
                    dense
                  />
                </span> -->
                <!-- <span v-else> -->
                {{ getFormatQuantity(item.dUsedQuantity) }}
                <!-- </span> -->
              </td>
              <!-- #endregion Fila cantidad recibida -->

              <!-- #region Fila unidad de medida -->
              <td class="text-center">
                <div class="d-flex justify-center">
                  <span v-if="item.bEditConcept">
                    <v-select
                      v-model="item.sMeasureUnitId"
                      item-text="sName"
                      item-value="sMeasureUnitId"
                      :items="aMeasureUnit"
                      placeholder="Unidad de medida"
                      solo
                      flat
                      class="input-filled-global w-150"
                      hide-details
                      dense
                      no-data-text="Sin información"
                    >
                    </v-select>
                  </span>
                  <span v-else>
                    {{ getMeasureUnitName(item.sMeasureUnitId) }}
                  </span>
                </div>
              </td>
              <!-- #endregion Fila unidad de medida -->

              <!-- #region Fila precio unitario -->
              <td class="text-end">
                <div class="d-flex justify-end">
                  <span v-if="item.bEditConcept">
                    <v-text-filed-currency
                      v-model="item.dUnitPrice"
                      :options="oOptionsCurrency"
                      solo
                      flat
                      sClass="input-filled-global w-150"
                      placeholder="Precio unitario"
                      hide-details
                      dense
                    />
                  </span>
                  <span v-else>
                    $ {{ getFormatMoney(item.dUnitPrice) }} MXN
                  </span>
                </div>
              </td>
              <!-- #endregion Fila precio unitario -->

              <!-- #region Fila importe  -->
              <td class="text-end W-S-nowrap">
                ${{ sumatoriaImporte(item.dQuantity, item.dUnitPrice) }} MXN
              </td>

              <!-- #endregion Fila importe  -->

              <!-- #region Fila acción -->
              <td>
                <div v-if="item.bEditConcept" class="d-flex justify-center">
                  <v-tooltip top color="black">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        @click="setChangeEditConcept(item)"
                        :loading="item.bLoadingEditConcept"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <span class="mdi mdi-check icon-check-table-global" />
                      </v-btn>
                    </template>
                    <span>Editar concepto</span>
                  </v-tooltip>

                  <v-tooltip top color="black">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        @click="setCancelEditConcept(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <span class="mdi mdi-close icon-delete-table-global" />
                      </v-btn>
                    </template>
                    <span>Cancelar edición</span>
                  </v-tooltip>
                </div>
                <div v-else class="d-flex justify-center">
                  <v-btn icon @click="setEditConcept(item)">
                    <span class="mdi mdi-pencil icon-eye-table-global" />
                  </v-btn>

                  <!-- deleteItemMaterial -->
                  <v-btn icon @click="setDeleteConcept(item)">
                    <span class="mdi mdi-delete icon-delete-table-global" />
                  </v-btn>
                </div>
              </td>
              <!-- #endregion Fila acción -->
            </tr>
            <!-- #endregion cuerpo de tabla con arreglo lleno -->

            <!-- #region fila con validación "Sin información" -->
            <tr v-if="bEmptyTable">
              <td
                class="text-center txt-empty-global"
                :colspan="aHeaderTable.length"
              >
                "Sin información"
              </td>
            </tr>
            <!-- #endregion fila con validación "Sin información" -->
          </tbody>

          <div v-show="bLoading">
            <v-progress-linear
              indeterminate
              color="yellow darken-2"
            ></v-progress-linear>
            <div class="content-text-loading">
              <p class="text-loading">
                Cargando... Espere por favor
              </p>
            </div>
          </div>

          <!-- #region contenedor para agregar concepto -->
          <tfoot v-if="!bLoading">
            <tr>
              <!-- #region input concepto -->
              <td>
                <v-text-field
                  v-model="sConcept"
                  class="input-filled-global min-w-200"
                  placeholder="Concepto"
                  solo
                  flat
                  hide-details
                  dense
                ></v-text-field>
              </td>
              <!-- #endregion input concepto -->

              <!-- #region input cantidad acordada -->
              <td>
                <div class="d-flex justify-center">
                  <!-- <v-text-filed-currency
                    v-model="dQuantity"
                    :options="oOptionUnit"
                    solo
                    flat
                    sClass="input-filled-global w-100"
                    placeholder="Cantidad"
                    hide-details
                    dense
                  /> -->
                  <v-text-field
                    type="number"
                    v-model="dQuantity"
                    solo
                    flat
                    class="input-filled-global w-100"
                    placeholder="Cantidad"
                    :step="0.01"
                    hide-details
                    dense
                  >
                  </v-text-field>
                </div>
              </td>
              <!-- #endregion input cantidad acordada -->

              <td></td>

              <!-- #region input unidad de medida -->
              <td>
                <div class="d-flex justify-center">
                  <v-select
                    v-model="sMeasureUnitId"
                    item-text="sName"
                    item-value="sMeasureUnitId"
                    :items="aMeasureUnit"
                    placeholder="Unidad de medida"
                    solo
                    flat
                    class="input-filled-global w-150"
                    hide-details
                    dense
                    no-data-text="Sin información"
                  >
                  </v-select>
                </div>
              </td>
              <!-- #endregion input unidad de medida -->

              <!-- #region input precio unitario  -->
              <td>
                <div class="d-flex justify-end">
                  <v-text-filed-currency
                    v-model="dUnitPrice"
                    :options="oOptionsCurrency"
                    solo
                    flat
                    sClass="input-filled-global w-150"
                    placeholder="0.00"
                    hide-details
                    dense
                  />
                </div>
              </td>
              <!-- #endregion input precio unitario  -->

              <!-- #region importe -->
              <td class="W-S-nowrap text-end">
                ${{ sumatoriaImporte(dQuantity, dUnitPrice) }}
                MXN
              </td>
              <!-- #endregion importe -->

              <!-- #region btn agregar concepto -->
              <td class="text-center">
                <v-btn
                  color="primary"
                  width="100"
                  class="btn-primary-global mr-2"
                  :loading="bLoadingAddconcept"
                  :disabled="!bValidateAddConcept"
                  @click="setConcept"
                  >Añadir</v-btn
                >
              </td>
              <!-- #endregion btn agregar concepto -->
            </tr>

            <tr>
              <td :colspan="aHeaderTable.length" class="td-divider-global">
                <div class="content-divider-global"></div>
              </td>
            </tr>

            <!-- #region subtotal prices -->
            <tr>
              <td colspan="6">
                <div class="content-advance-purchase-subtotal-global">
                  <span class="txt-advance-purchase-subtotal-global">
                    SUBTOTAL
                  </span>
                  <span class="ml-2"> | </span>
                  <span class="color-green-subtotal-global ml-2"
                    >${{ sumatoriaMateriales() }} MXN</span
                  >
                </div>
              </td>
            </tr>
            <!-- #endregion subtotal prices -->
          </tfoot>
          <!-- #endregion contenedor para agregar concepto -->
        </template>
      </v-simple-table>
    </div>

    <!-- #endregion contenedor tabla -->
  </div>
</template>
<script>
export default {
  props: {
    // import texts from admin
    texts: Object,
    mdUp: Boolean,
    smUp: Boolean,
  },
  data() {
    return {
      sSearch: "",
      bLoading: false,
      bEmptyTable: false,
      aHeaderTable: [
        {
          sName: "Concepto",
          sClass: "text-start",
        },
        {
          sName: "Cantidad comprada",
          sClass: "text-center w-100",
        },
        {
          sName: "Cantidad recibida",
          sClass: "text-center w-100",
        },
        {
          sName: "Unidad de medida",
          sClass: "text-center w-80",
        },
        {
          sName: "Precio unitario",
          sClass: "text-end w-100",
        },
        {
          sName: "Importe",
          sClass: "text-end",
        },
        {
          sName: "Acción",
          sClass: "text-center w-100",
        },
      ],
      itemsRequestPerConcept: [
        // {
        //   sConcept: "Concepto de prueba 1",
        //   dUsedQuantity: 10,
        //   dQuantity: 20,
        //   sMeasureUnitId: "623ba552-a43b-45dd-8f0a-4278a659ab7d",
        //   sMeasureUnitName: "bulto",
        //   dUnitPrice: 2000,
        //   bEditConcept: false,
        // },
        // {
        //   sConcept: "Concepto de prueba 1",
        //   dUsedQuantity: 15,
        //   dQuantity: 30,
        //   sMeasureUnitId: "623ba552-a43b-45dd-8f0a-4278a659ab7d",
        //   sMeasureUnitName: "bulto",
        //   dUnitPrice: 300,
        //   bEditConcept: false,
        // },
      ],
      aMeasureUnit: [],
      // #region propiedades de modificación del concepto
      sConceptEdit: "",
      dQuantityEdit: "",
      sMeasureUnitIdEdit: "",
      sMeasureUnitNameEdit: "",
      dUnitPriceEdit: "",
      // #endregion propiedades de modificación del concepto

      // #region propiedades de alta del concepto
      sConcept: "",
      dQuantity: "",
      sMeasureUnitId: null,
      sMeasureUnitName: "",
      dUnitPrice: "",
      bLoadingAddconcept: false,
      // #endregion propiedades de alta del concepto

      oOptionsCurrency: {
        locale: "en-US",
        prefix: "$",
        suffix: "MXN",
        length: 11,
        precision: 2,
      },
      oOptionUnit: {
        locale: "en-US",
        prefix: "",
        suffix: "",
        length: 11,
        precision: 0,
      },
    };
  },
  beforeMount() {
    this.getAdvancePurchase();
    this.getMeasureUnit();
  },
  methods: {
    getAdvancePurchase() {
      db.get(
        `${uri}/api/v1/supplier/${this.$route.params.sSupplierId}/products`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-tk"),
            "content-type": "application/x-www-form-urlencoded;charset=utf-8",
          },
          params: {
            sSearch: this.sSearch,
            iPageNumber: 1,
            // iItemsPerPage: 0,
            // iPageNumber: this.iCurrentPage,
            // iItemsPerPage: this.iItemsPerPage,
          },
        }
      )
        .then((resp) => {
          this.itemsRequestPerConcept = resp.data.results.map((e) => {
            return {
              sSupplierProductId: e.sSupplierProductId,
              sConcept: e.sName,
              dUsedQuantity: e.dUsedQuantity,
              dQuantity: e.dInitialQuantity,
              sMeasureUnitId: e.sMeasureUnitId,
              sMeasureUnitName: e.sMeasureUnitName,
              dUnitPrice: e.dUnitPrice,
              bEditConcept: false,
              bLoadingEditConcept: false,
            };
          });
          this.$store.commit("refresher", false);
        })
        .catch((err) => {
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    sumatoriaImporte(dQuantity, dUnitPrice) {
      var sum = 0;
      sum = sum + Number(dQuantity) * Number(dUnitPrice);
      return this.getFormatMoney(sum);
    },
    deleteItemMaterial(e) {
      if (this.itemsRequestPerConcept.length > 1) {
        this.itemsRequestPerConcept.splice(e, 1);
      } else {
        this.bLoading = false;
        const alert = {
          color: "red",
          text: "No se puede eliminar todos los conceptos.",
        };
        this.$store.commit("toggle_alert", alert);
      }
    },
    isNumber(evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    notCero(cantidadMaterial) {
      if (cantidadMaterial !== "") {
        if (cantidadMaterial <= 0) {
          const alert = {
            color: "red",
            text: "Es necesario agregar una cantidad mayor a 0",
          };
          this.$store.commit("toggle_alert", alert);
          this.cantidadMaterial = "";
        }
      }
    },
    getMeasureUnitName(sMeasureUnitId) {
      if (sMeasureUnitId && this.aMeasureUnit.length > 0) {
        var oMeasureUnit = this.aMeasureUnit.filter(
          (val) => val.sMeasureUnitId === sMeasureUnitId
        )[0];
        return oMeasureUnit.sName;
      } else {
        return "";
      }
    },
    changeUnidadMedidaMaterial(e) {
      var text = this.aMeasureUnit.filter((val) => val.sMeasureUnitId === e);
      this.unidadMedidaMaterialText = text[0].sName;
    },
    setConcept() {
      // const arr = {
      //   sConcept: this.sConcept,
      //   dUsedQuantity: 0,
      //   dQuantity: this.dQuantity,
      //   sMeasureUnitId: this.sMeasureUnitId,
      //   sMeasureUnitName: this.sMeasureUnitName,
      //   dUnitPrice: this.dUnitPrice,
      //   bEditConcept: false,
      // };

      // this.itemsRequestPerConcept.push(arr);
      // this.bLoadingAddconcept = true;
      const payload = {
          sName: this.sConcept,
          dInitialQuantity: this.dQuantity,
          dUnitPrice: this.dUnitPrice,
          sMeasureUnitId: this.sMeasureUnitId,
        },
        config = {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("user-tk"),
            contentType: "application/x-www-form-urlencoded;charset=utf-8",
          },
        };

      //post credentials and get access token from laravel backend
      db.post(
        `${uri}/api/v1/supplier/${this.$route.params.sSupplierId}/products`,
        payload,
        config
      )
        .then((response) => {
          this.bLoadingAddconcept = false;
          const alert = {
            color: "green",
            text: response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
          this.$store.commit("refresher", true);
          this.setClearItemsConcept();
        })
        .catch((err) => {
          this.bLoadingAddconcept = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    setClearItemsConcept() {
      this.sConcept = "";
      this.dQuantity = "";
      this.sMeasureUnitId = null;
      this.dUnitPrice = "";
    },
    sumatoriaMateriales() {
      var sum = 0;
      for (let i = 0; i < this.itemsRequestPerConcept.length; i++) {
        const element = this.itemsRequestPerConcept[i];
        sum = sum + element.dQuantity * element.dUnitPrice;
      }
      return this.getFormatMoney(sum);
    },
    getFormatMoney(number) {
      let convert_number = Number(number).toFixed(2);
      return convert_number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getFormatQuantity(number) {
      let convert_number = Number(number).toFixed(0);
      return convert_number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getMeasureUnit() {
      db.get(`${uri}/api/v1/measure_units`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.aMeasureUnit = resp.data.units;
        })
        .catch((err) => {
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    setEditConcept(item) {
      for (let i = 0; i < this.itemsRequestPerConcept.length; i++) {
        const element = this.itemsRequestPerConcept[i];
        element.bEditConcept = false;
      }
      item.bEditConcept = true;
    },
    setCancelEditConcept(item) {
      for (let i = 0; i < this.itemsRequestPerConcept.length; i++) {
        const element = this.itemsRequestPerConcept[i];
        element.bEditConcept = false;
      }
      item.bEditConcept = false;
      this.$store.commit("refresher", true);
    },
    setChangeEditConcept(item) {
      item.bLoadingEditConcept = true;
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
        },
      };
      const params = {
        sName: item.sConcept,
        dInitialQuantity: item.dQuantity,
        dUnitPrice: item.dUnitPrice,
        sMeasureUnitId: item.sMeasureUnitId,
      };
      db.put(
        `${uri}/api/v1/supplier/${this.$route.params.sSupplierId}/products/${item.sSupplierProductId}`,
        params,
        config
      )
        .then((resp) => {
          const alert = {
            color: "green",
            text: resp.data.message,
          };
          this.$store.commit("toggle_alert", alert);
          this.$store.commit("refresher", true);
          item.bLoadingEditConcept = false;
          item.bEditConcept = false;
        })
        .catch((err) => {
          item.bLoadingEditConcept = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    setDeleteConcept(item) {
      this.$store.commit("setDeleteItems", {
        id: item.sSupplierProductId,
        route: `/api/v1/supplier/${this.$route.params.sSupplierId}/products/${item.sSupplierProductId}`,
        unit: " concepto.",
        msgDelete: "Estás a punto de eliminar un elemento",
        msgQuestionDelete: "¿Estás seguro que deseas realizar esta acción?",
      });
    },
  },
  computed: {
    refreshTable() {
      return this.$store.state.refresh;
    },
    bValidateAddConcept() {
      return (
        this.sConcept !== "" &&
        (this.dQuantity !== "" || this.dQuantity > 0) &&
        this.sMeasureUnitId !== null &&
        (this.dUnitPrice !== "" || this.dUnitPrice > 0)
      );
    },
  },
  watch: {
    refreshTable: function() {
      this.getAdvancePurchase();
    },
    sSearch() {
      this.getAdvancePurchase();
    },
  },
};
</script>
<style>
/* #region estilo de contenedor de filtros */
.content-advance-purchase-search-global {
  max-width: 350px !important;
}
/* #endregion estilo de contenedor de filtros */

.content-advance-purchase-table-all-global {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  /* box-shadow: 0px 3px 40px #00000019 !important; */
  border-radius: 20px !important;
}

.content-advance-purchase-subtotal-global {
  display: flex;
  justify-content: flex-end;
  font-family: "Poppins", sans-serif !important;
  font-weight: bold !important;
  font-size: 16px;
}

.content-tooltip-global {
  background-color: red !important;
}
</style>
